'use client';

import { HomeHeroBannerStoryblok } from '@/types/types-storyblok';
import { Lora } from 'next/font/google';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import Image from 'next/image';
import { useRef } from 'react';
import { storyblokEditable } from '@storyblok/js';
import { StoryblokComponent } from '../storyblokComponent';
import { StoryblokReactComponentProps } from '../componentsMap';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export function StoryblokHomeHeroBanner({ component, story }: StoryblokReactComponentProps<HomeHeroBannerStoryblok>) {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    const nextSection = containerRef.current?.nextElementSibling;
    if (nextSection && 'offsetTop' in nextSection && typeof nextSection.offsetTop === 'number') {
      return window.scrollTo({ top: nextSection.offsetTop, behavior: 'smooth' });
    }
    window.scrollTo({ top: document.querySelector('footer')?.offsetTop ?? 0, behavior: 'smooth' });
  };

  const [content] = component.content;

  const isSearchBannerPlacedBeforeImage = content.component === 'homeHeroBannerSearchForm' && content.mobilePosition === 'before-image';

  return (
    <section
      ref={containerRef}
      className="relative flex flex-col gap-10 pt-11 lg:items-start lg:pt-28 max-w-section xl:mx-auto"
      {...storyblokEditable(component)}
    >
      <div className="absolute h-full w-full inset-0 hidden lg:block overflow-x-hidden">
        <Image
          className="object-contain object-right hidden lg:block lg:translate-x-20 xl:translate-x-0"
          src={component.desktopBackgroundImage.filename}
          aria-hidden="true"
          alt={component.desktopBackgroundImage.alt ?? ''}
          fill
        />
      </div>

      <h1 className="font-semibold text-neutral-darker text-4xl md:text-5xl px-9 text-center relative z-[2] lg:px-0 lg:pl-20 lg:text-left xl:max-w-screen-xl xl:w-full xl:inline-block xl:mx-auto xl:pl-0 xl:text-6xl">
        {render(
          component.title,
          makeRichTextRenderOptions(story, {
            markResolvers: {
              [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
            },
          }),
        )}
      </h1>
      {component.subtitle && (
        <h2 className="text-neutral-darker font-medium text-2xl md:text-xl lg:text-2xl lg:font-medium px-14 text-center relative z-[2] lg:pl-20 lg:text-left xl:max-w-screen-xl xl:w-full xl:inline-block xl:mx-auto xl:pl-0">
          {component.subtitle}
        </h2>
      )}
      {(content.component === 'homeHeroBannerRichtextWithCta' || content.component === 'homeHeroBannerSelect') && (
        <div className="text-center lg:pl-20 lg:text-left lg:w-full xl:max-w-screen-xl xl:mx-auto xl:pl-0">
          <div className="w-full lg:w-5/12 px-4 lg:pl-0 xl:pr-0 text-lg font-normal">
            {render(
              content.content,
              makeRichTextRenderOptions(story, {
                markResolvers: {
                  [MARK_BOLD]: child => <span className="font-semibold">{child}</span>,
                },
              }),
            )}
          </div>
        </div>
      )}


      {(content.component === 'homeHeroBannerRichtextWithCta' || content.component === 'homeHeroBannerSelect') && (
        <StoryblokComponent story={story} component={content} />
      )}
      {isSearchBannerPlacedBeforeImage && (
        <div className="lg:hidden">
          <StoryblokComponent story={story} component={content} />
        </div>
      )}

      <Image
        className="md:hidden w-full h-auto object-contain px-4"
        src={component.mobileBackgroundImage.filename}
        aria-hidden="true"
        height={262}
        width={262}
        alt={component.mobileBackgroundImage.alt ?? ''}
      />

      <Image
        className="hidden md:block lg:hidden w-full h-auto object-contain px-11 pointer-events-none"
        src={component.tabletBackgroundImage.filename}
        aria-hidden="true"
        height={539}
        width={681}
        alt={component.tabletBackgroundImage.alt ?? ''}
      />


      {content.component !== 'homeHeroBannerRichtextWithCta' && content.component !== 'homeHeroBannerSelect' && (
        <StoryblokComponent story={story} component={content} />
      )}
      {isSearchBannerPlacedBeforeImage && (
        <div className="hidden lg:block">
          <StoryblokComponent story={story} component={content} />
        </div>
      )}


      {component.showDiscoverMore ? (
        <button
          className={clsx(
            'text-black flex flex-row items-center justify-center lg:justify-start gap-4 mt-6  lg:ml-20 lg:mb-28 z-[2] relative transition-all duration-300 translate-y-0 hover:-translate-y-1 hover:text-elty-green hover:fill-elty-green focus:-translate-y-1 focus:text-elty-green focus:fill-elty-green  lg:top-0 xl:max-w-screen-xl xl:mx-auto xl:w-full',
            !isSearchBannerPlacedBeforeImage && '-mb-2 md:-mb-24 -top-14 md:-top-40',
          )}
          onClick={scrollDown}
        >
          <span>Scopri di più</span>
          <svg
            className="fill-inherit"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="scroll down icon"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.1919 0.933058C14.436 1.17714 14.436 1.57286 14.1919 1.81694L7.94194 8.06694C7.69786 8.31102 7.30214 8.31102 7.05806 8.06694L0.808058 1.81694C0.563981 1.57286 0.563981 1.17714 0.808058 0.933059C1.05214 0.688981 1.44786 0.688981 1.69194 0.933059L7.5 6.74112L13.3081 0.933058C13.5521 0.688981 13.9479 0.688981 14.1919 0.933058ZM14.1919 5.93306C14.436 6.17714 14.436 6.57286 14.1919 6.81694L7.94194 13.0669C7.69786 13.311 7.30214 13.311 7.05806 13.0669L0.808058 6.81694C0.563981 6.57286 0.563981 6.17714 0.808058 5.93306C1.05214 5.68898 1.44786 5.68898 1.69194 5.93306L7.5 11.7411L13.3081 5.93306C13.5521 5.68898 13.9479 5.68898 14.1919 5.93306Z"
            />
          </svg>
        </button>
      ) : (
        <div className="hidden lg:block lg:my-16"></div>
      )}
    </section>
  );
}
